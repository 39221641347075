<template>
  <div class="sign_in">
    <div class="return_icon">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
    </div>
    <div class="sign_in_main">
      <div class="sign_in_title1">您好！</div>
      <div class="sign_in_title2">欢迎您参加面试</div>
      <div class="sign_in_title3">您的面试时间为</div>
      <div class="sign_in_title4">2023年12月23日 12:34 - 13:34</div>
      <div class="sign_box">
        <div class="sign_btn" @click="$router.go(-1)">签到</div>
        <div class="sign_btn_btm">
          <i class="el-icon-location-outline"></i>
          您已进入可签到区域
        </div>
      </div>
    </div>

    <div>
        h5
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      location: null,
    };
  },
  mounted() {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log(position);
      },
      function (error) {
        console.log(error);
        alert("获取失败! " + error.code + " , " + error.message);
      },
      {
        timeout: 5000, //超时时间
        enableHighAccuracy: true,
        maximumAge: 1000000, //最大缓存时间
      }
    );
  },
  methods: {},
};
</script>

<style scoped>
.sign_in {
  min-height: 100vh;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  background: #fff;
}
.return_icon {
  height: 2.7rem;
  line-height: 2.7rem;
}
.sign_in_main {
  box-sizing: border-box;
  padding: 0 2rem;
}
.sign_in_title1 {
  color: #5b5b5b;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 2.5rem;
}
.sign_in_title2 {
  color: #a9a9a9;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.sign_in_title3 {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 5rem;
}
.sign_in_title4 {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.sign_box {
  margin-top: 5rem;
  text-align: center;
}
.sign_btn {
  display: inline-block;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  text-align: center;
  line-height: 6.25rem;
  color: #fff;
  font-size: 1.5rem;
  background: linear-gradient(313deg, #18aef3 -66.5%, #0a76e2 83.19%);
}
.sign_btn_btm {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 1.25rem;
}
.box_map {
  width: 100%;
  height: 300px;
  background: pink;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}
</style>
